import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticImage } from '@/hooks/use-static-image'
import { classNames } from '@/utils/class-names'
import isArray from 'lodash/isArray'
import { Link } from 'gatsby'
import { ChevronRightIcon } from '@heroicons/react/outline'

export const ProductFeatureRow = ({
  title,
  subTitle,
  description,
  index,
  imageName,
  loading = `lazy`,
  ctaText,
  ctaLink,
}) => {
  const isEven = index % 2 == 0

  const Image = useStaticImage(imageName)

  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-2 md:my-16'>
        <div className={isEven ? `order-first` : `md:order-last`}>
          <div className={`align-middle`}>
            <Image loading={loading} className={`rounded-none min-h-full`} />
          </div>
        </div>

        <div className='container mx-auto px-5 pb-5'>
          <h2 className='mt-5 text-base font-semibold uppercase tracking-wider'>
            {subTitle}
          </h2>
          <p className='mt-2 mb-2 text-2xl font-extrabold uppercase tracking-tight sm:text-4xl sm:mt-2'>
            {title}
          </p>
          {isArray(description) ? (
            description.map((p, i) => (
              <p key={i} className='mt-3'>
                {p}
              </p>
            ))
          ) : (
            <p className='mt-3'>{description}</p>
          )}

          {ctaText && (
            <div className='mt-8'>
              <div className='inline-flex rounded-md shadow'>
                <Link
                  className='inline-flex items-center justify-center px-5 py-3 border border-transparent text-base uppercase font-medium rounded-sm text-white bg-gray-900 hover:bg-gray-800 transition-colors'
                  to={ctaLink}
                >
                  {ctaText}
                  <ChevronRightIcon
                    className='-mr-1 ml-3 h-5 w-5 text-white'
                    aria-hidden='true'
                  />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      {/*
      <div className='prose-base bg-concrete-500 text-mine-shaft'>
        <div className='container mx-auto'>
          <div className={classNames(`flex flex-col md:flex-row`)}>
            <div
              className={classNames(
                `flex flex-col justify-center p-4 min-w-full md:p-8 md:min-w-1/2`,
                isEven ? `order-last` : `md:order-first`,
              )}
            >
              <h2 className='uppercase bold'>{subTitle}</h2>
              <h1 className='uppercase'>{title}</h1>

              <p className='text-lg'>{description}</p>
            </div>
            <div
              className={classNames(
                `flex flex-col justify-center min-w-full md:min-w-1/2`,
                !isEven && `order-first md:order-last`,
              )}
            >
              <Image loading={loading} className={`rounded-none min-h-full`} />
            </div>
          </div>
        </div>
      </div>
              */}
    </>
  )
}

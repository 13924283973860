import { StoreContext } from '@/context/store-context'
import { useGtag } from '@/hooks/use-gtag'
import { useScrollLock } from '@/hooks/use-scroll-lock'
import get from 'lodash/get'
import React, { useEffect } from 'react'
import { Cart } from '@/index.d'
import { preventDefault } from '@/utils/prevent-default'
import { formatPrice } from '@/utils/format-price'

export function useCart<T> (): Cart {
  const gtag = useGtag()
  const {
    client,
    checkout,
    setCheckout,
    cartState,
    updateCartState,
  } = React.useContext(StoreContext)

  useEffect(() => {
    window.addCartDiscountCode = addCartDiscountCode

    const cartCount = checkout.lineItems.reduce((total, item) => {
      return total + item.quantity
    }, 0)

    const cartSavingsSale = {
      beforeSale: String(
        checkout.lineItems.reduce(
          (total, item) =>
            total + Number(get(item, 'variant.compareAtPrice', '0')),
          0,
        ),
      ),

      savingsTotal: String(
        checkout.lineItems.reduce((total, item) => {
          const price = Number(get(item, 'variant.price', '0'))
          const compareAtPrice = Number(
            get(item, 'variant.compareAtPrice', '0'),
          )
          const saving = Number(price - compareAtPrice)

          return compareAtPrice ? total + saving : total
        }, 0),
      ),

      subTotal: 0,
    }

    const cartItemSavings = checkout?.lineItems
      ?.map(lineItem => ({
        price: get(lineItem, 'variant.price', null),
        compareAtPrice: get(lineItem, 'variant.compareAtPrice', null),
        discountCodes: lineItem.discountAllocations.reduce(
          (acc, discountApplication) => [
            ...acc,
            {
              code: discountApplication.attrs.discountApplication.code,
              amount: discountApplication.attrs.allocatedAmount.amount,
            },
          ],
          [],
        ),
      }))
      .map(lineItem => ({
        ...lineItem,
        totalSaleSavings:
          Number(lineItem.compareAtPrice) - Number(lineItem.price),
        totalDiscountSavings: lineItem.discountCodes.reduce(
          (total, x) => Number(total) + Number(x.amount),
          0,
        ),
      }))
      .map(lineItem => ({
        ...lineItem,
        totalSavings:
          Number(lineItem.totalSaleSavings) +
          Number(lineItem.totalDiscountSavings),
        finalPrice:
          Number(lineItem.price) - Number(lineItem.totalDiscountSavings),
      }))

    const cartTotalSavings = cartItemSavings.reduce(
      (acc, x) => ({
        totalSaleSavings: acc.totalSaleSavings + Number(x.totalSaleSavings),
        totalDiscountSavings:
          acc.totalDiscountSavings + Number(x.totalDiscountSavings),
        totalSavings:
          acc.totalSavings +
          Number(x.totalSaleSavings) +
          Number(x.totalDiscountSavings),
      }),
      { totalSaleSavings: 0, totalDiscountSavings: 0, totalSavings: 0 },
    )

    const totalPrice = Number(get(checkout, 'totalPriceV2.amount', '0'))

    const cartTotalBeforeSavings = {
      totalBeforeDiscountSavings:
        Number(totalPrice) + Number(cartTotalSavings.totalDiscountSavings),
      totalBeforeSaleSavings:
        Number(totalPrice) + Number(cartTotalSavings.totalSaleSavings),
      totalBeforeSavings:
        Number(totalPrice) + Number(cartTotalSavings.totalSavings),
    }

    const cartTotalAfterSavings = {
      totalAfterDiscountSavings:
        Number(cartTotalBeforeSavings.totalBeforeSavings) -
        Number(cartTotalSavings.totalDiscountSavings),
      totalAfterSaleSavings:
        Number(cartTotalBeforeSavings.totalBeforeSavings) -
        Number(cartTotalSavings.totalSaleSavings),
      totalAfterSavings: totalPrice,
    }

    const cartDiscountCodes =
      checkout?.discountApplications?.map(
        ({
          allocationMethod,
          applicable,
          code,
          targetSelection,
          targetType,
          value: {
            percentage,
            type: { name },
          },
        }) => ({
          code,
          percentage,
          name,
        }),
      ) || []

    const cartSavings = {
      totalPrice,
      ...cartTotalSavings,
      ...cartTotalBeforeSavings,
      ...cartTotalAfterSavings,
    }

    updateCartState({
      cartSavings,
      cartDiscountCodes,
      isCartDiscountActive: cartDiscountCodes.length > 0,
      cartItems: checkout.lineItems,
      cartCurrencyCode: get(checkout, 'totalPriceV2.currencyCode', 'USD'),
      cartTotal: get(checkout, 'totalPriceV2.amount', '0'),
      cartShippingTotal: get(checkout, 'totalShippingV2.amount', '0'),
      cartTaxTotal: get(checkout, 'totalTaxV2.amount', '0'),
      cartSubtotal: get(checkout, 'subtotalPriceV2.amount', '0'),
      cartSavingsSale,
      isCartEmpty: cartCount === 0,
      cartCount: String(cartCount),
    })
  }, [checkout])

  const [isScrollLocked, setIsScrollLocked] = useScrollLock(false)

  const setIsCartVisible = (value: boolean) => {
    setIsScrollLocked(value)
    gtag('event', 'view_cart', cartState)

    updateCartState({ isCartVisible: value })
  }

  const setIsCartLoading = (isCartLoading: boolean) => {
    updateCartState({
      isCartLoading,
    })
  }

  const setCartDiscountCode = async (cartDiscountCode: string | null) => {
    setIsCartLoading(true)

    const prev = checkout.id
    const next =
      cartDiscountCode !== null
        ? await client.checkout.addDiscount(prev, String(cartDiscountCode))
        : await client.checkout.removeDiscount(prev)

    updateCartState({
      isCartLoading: false,
      isCartDiscountActive: cartDiscountCode !== null,
      didJustAddCartDiscountCode: cartDiscountCode !== null,
      didJustRemoveCartDiscountCode: cartDiscountCode === null,
      cartDiscountCode,
    })

    setCheckout(next)

    // setTimeout(
    //   () =>
    //     updateCartState({
    //       cartDiscountCode,
    //       didJustAddCartDiscountCode: false,
    //       didJustRemoveCartDiscountCode: false,
    //     }),
    //   3000,
    // )
  }

  const addCartItem = async (variantId: string, quantity: string) => {
    setIsCartLoading(true)

    const prev = checkout

    const next = await client.checkout.addLineItems(prev.id, [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ])

    setCheckout(next)

    updateCartState({ cartIsLoading: false, didJustAddCartItem: true })

    // setTimeout(() => {
    //   setCheckout(next)
    //   updateCartState({ didJustAddCartItem: false })
    // }, 3000)
  }

  const removeCartItem = async (lineItemID: string) => {
    setIsCartLoading(true)

    const next = await client.checkout.removeLineItems(checkout.id, [
      lineItemID,
    ])

    setCheckout(next)

    updateCartState({ cartIsLoading: false, didJustRemoveCartItem: true })

    // setTimeout(() => {
    //   setCheckout(next)
    //   updateCartState({ didJustRemoveCartItem: false })
    // }, 3000)
  }

  const updateCartItem = async (lineItemID: string, quantity: string) => {
    setIsCartLoading(true)

    const next = await client.checkout.updateLineItems(checkout.id, [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ])

    setCheckout(next)

    updateCartState({ cartIsLoading: false })
  }

  const onClickRemoveCartDiscountCode = discountCode => ev => {
    preventDefault(ev)
    removeCartDiscountCode()
  }

  const onClickShowCart = ev => {
    preventDefault(ev)
    setIsCartVisible(true)
  }

  const onClickHideCart = ev => {
    preventDefault(ev)
    setIsCartVisible(false)
  }

  const onClickToggleCartVisible = () =>
    setIsCartVisible(!!cartState.isCartVisible)

  const onClickCheckout = ev => {
    preventDefault(ev)

    gtag('event', 'begin_checkout', cartState.cartItems)

    const url = new URL(checkout.webUrl)
    url.host = 'checkout.anov.us'

    window.open(url.toString())
  }

  const addCartDiscountCode = (discountCode: string) =>
    setCartDiscountCode(discountCode)

  const removeCartDiscountCode = () => setCartDiscountCode(null)

  return {
    ...cartState,
    onClickRemoveCartDiscountCode,
    onClickCheckout,
    onClickHideCart,
    onClickShowCart,
    onClickToggleCartVisible,
    setIsCartLoading,
    addCartDiscountCode,
    removeCartDiscountCode,
    addCartItem,
    updateCartItem,
    removeCartItem,
  }
}

export default useCart

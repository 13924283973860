import * as React from 'react'
import { getShopifyImage } from 'gatsby-source-shopify'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import has from 'lodash/has'

import { StoreContext } from '@/context/store-context'
import { formatPrice } from '@/utils/format-price'

export const useProductCard = product => {
  const defaultImageHeight = 200
  const defaultImageWidth = 200

  const { title, priceRangeV2, slug, media, vendor, storefrontImages } = product

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    priceRangeV2.minVariantPrice.amount,
  )

  let storefrontImageData = {}
  if (storefrontImages) {
    const storefrontImage = storefrontImages.edges[0].node
    try {
      storefrontImageData = getShopifyImage({
        image: storefrontImage,
        layout: 'fixed',
        width: defaultImageWidth,
        height: defaultImageHeight,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const images = media.map(media => ({
    media,
    gatsbyImageData: getShopifyImage({
      image: media.image,
      width: 300,
      layout: 'constrained',
      transformOptions: {
        trim: 5,
      },
    }),
  }))

  const [thumb] = images

  const hasImage = get(thumb, 'gatsbyImageData', storefrontImageData)

  return {
    thumb,
    hasImage,
    storefrontImageData,
    title,
    price,
    slug,
    images,
    vendor,
    storefrontImages,
    defaultImageHeight,
    defaultImageWidth,
  }
}

export const useProduct = product => {
  const {
    vendor,
    descriptionHtml,
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    media,
    media: [firstImage],
  } = product

  const images = media.map(media => ({
    media,
    gatsbyImageData: getShopifyImage({
      image: media.image,
      width: 400,
      layout: 'constrained',
      transformOptions: {
        trim: 5,
      },
    }),
  }))

  const tinyThumbs = media.map(media => ({
    media,
    gatsbyImageData: getShopifyImage({
      image: media.image,
      width: 64,
      layout: 'constrained',
      transformOptions: {
        trim: 5,
      },
    }),
  }))

  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale,
  )

  const checkAvailablity = React.useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        const result =
          fetchedProduct?.variants.filter(
            variant => variant.id === productVariant.storefrontId,
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product],
  )

  const handleOptionChange = (index, v) => {
    const value = get(v, 'target.value', '')

    if (value === '') {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find(variant => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setAvailable(selectedVariant.availableForSale)

    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    get(priceRangeV2, 'minVariantPrice.currencyCode', 'USD'),
    variant.price,
  )

  const compareAtPrice = has(variant, 'compareAtPrice')
    ? formatPrice(
        get(priceRangeV2, 'minVariantPrice.currencyCode', 'USD'),
        variant.compareAtPrice,
      )
    : null

  const hasVariants = variants.length > 1
  const hasImages = media.length > 0
  const hasMultipleImages = true || media.length > 1

  return {
    vendor,
    descriptionHtml,
    available,
    options,
    variants,
    initialVariant,
    firstImage,
    title,
    description,
    images,
    quantity,
    setQuantity,
    productVariant,
    setAvailable,
    checkAvailablity,
    handleOptionChange,
    price,
    hasVariants,
    hasImages,
    hasMultipleImages,
    tinyThumbs,
    compareAtPrice,
  }
}

export default useProduct

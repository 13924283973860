import { GatsbyImage } from 'gatsby-plugin-image'
import chunk from 'lodash/chunk'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import range from 'lodash/range'
import React, { memo, useEffect, useRef, useState, useMemo } from 'react'
import { getShopifyImage } from 'gatsby-source-shopify'

import { classNames } from '@/utils/class-names'

export const ProductCarousel = memo(
  ({ images, className }: { images: any[]; className?: string }) => {
    const cards = useMemo(
      () =>
        images.map((card, cardIndex) => ({
          ...card,
          thumb: {
            gatsbyImageData: getShopifyImage({
              image: card.media.image,
              width: 64,
              layout: 'constrained',
              transformOptions: {
                trim: 5,
              },
            }),
          },
        })),
      [images],
    )

    const [targetCardWidth, setTargetCardWidth] = useState(640)

    const carouselRef = useRef(null)
    const groupRefs: React.MutableRefObject<HTMLLIElement[]> = useRef([])

    const [currentGroupIndex, setHorseIndex] = useState(0)
    const [carouselWidth, setCarouselWidth] = useState(undefined)

    const cardsPerGroup = Math.max(
      1,
      Math.floor((carouselWidth ?? 0) / targetCardWidth),
    )

    const group = chunk(cards, cardsPerGroup)

    useEffect(() => {
      const updateCarouselWidth = () => {
        setCarouselWidth(get(carouselRef, 'current.offsetWidth', 0))
      }
      const onResize = debounce(() => {
        updateCarouselWidth()
      }, 300)

      window.addEventListener('resize', onResize)
      updateCarouselWidth()

      return () => {
        window.removeEventListener('resize', onResize)
      }
    }, [])

    const onClickThumb = (index: number) => (ev: React.MouseEvent<Element>) => {
      const el = groupRefs.current[index] as HTMLLIElement

      el.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    }

    useEffect(() => {
      const callback = (entries: any[]) => {
        entries.forEach(
          (entry: { isIntersecting: boolean; target: HTMLLIElement }) => {
            if (entry.isIntersecting) {
              const index = groupRefs.current.indexOf(entry.target)
              setHorseIndex(index)
            }
          },
        )
      }

      const observer = new IntersectionObserver(callback, {
        root: carouselRef.current,
        threshold: 0.6,
      })
      groupRefs.current
        .filter(h => h !== undefined)
        .forEach(card => {
          try {
            observer.observe(card)
          } catch (e) {}
        })

      return function cleanup () {
        observer.disconnect()
      }
    }, [group, cardsPerGroup, groupRefs])

    const getChunkRef = chunkIndex => li => (groupRefs.current[chunkIndex] = li)

    return (
      <div className={className}>
        <div
          className={classNames(
            `flex flex-col`,
            `-mx-5 w-[calc(100%+calc(2*1.25rem))] max-w-100vw overflow-x-hidden`,
            `sm:mx-5 sm:w-full sm:grid sm:grid-rows-90/10 sm:grid-cols-1`,
            `relative`,
            `xl:grid-rows-1 xl:grid-cols-90/10 w-full`,
            /* before:flex */ `before:hidden before:md:absolute before:pointer-events-none before:z-20 before:top before:content[" "] before:height-full before:min-w-1/5 before:bg-gradient-to-r before:from-white before:to-transparent before:h-full before:lg:min-w-1/16 before:xl:min-w-1/8`,
            /* after:flex */ `after:hidden after:md:absolute after:pointer-events-none after:z-20 after:top after:content[" "] after:height-full after:min-w-1/5 after:bg-gradient-to-r after:from-transparent after:to-white after:h-full after:ml-3/4 after:mr-0 after:lg:min-w-1/16 after:lg:justify-self-end after:xl:col-start-1 after:xl:ml-0 after:xl:mr-15p after:xl:min-w-1/8`,
          )}
        >
          <ul
            className={classNames(
              'flex flex-row',
              'sm:col-start-1 sm:col-span-1 sm:row-start-1 sm:row-span-1 overscroll-x-contain',
              'xl:col-start-1 xl:col-span-1 xl:row-start-1 xl:row-span-1 xl:gap-8',
              'flex relative overflow-x-scroll snap-x snap-mandatory scrollbar-hide',
              'before:flex before:content[" "] before:min-w-full before:h-full before:xl:w-3/4',
              'after:flex after:content[" "] after:min-w-full after:h-full',
            )}
            ref={carouselRef}
          >
            {group.map((card, chunkIndex: number) => (
              <li
                key={`${chunkIndex}-${card.map(c => c.id).join()}`} //card.map(card => card.id).join(',')}
                ref={getChunkRef(chunkIndex)}
                className={classNames(
                  `rounded-sm overflow-none`,
                  `min-w-full flex items-start justify-start snap-center cursor-zoom-in`,
                  `sm:mx-5`,
                  `md:snap-center md:justify-center md:min-w-2/3`,
                  `lg:snap-center lg:justify-center lg:min-w-full lg:mx-0`,
                  `xl:snap-center xl:justify-center xl:min-w-3/4 lg:mx-20`,
                )}
              >
                {card.map((card, groupIndex) => {
                  return (
                    <div
                      key={card.id}
                      className={classNames(
                        `sm:mb-7 shadow-xl rounded`,
                        `transition-all duration-200 ease-in-out`,
                        groupIndex === currentGroupIndex
                          ? 'xl:opacity-100'
                          : '',
                      )}
                    >
                      <GatsbyImage
                        objectFit='contain'
                        loading={groupIndex === 0 ? `eager` : `lazy`}
                        alt={card.altText}
                        image={card.gatsbyImageData}
                        className={classNames(
                          `w-full h-full object-center object-cover touch-pan-x md:pointer-events-none`,
                        )}
                      />
                    </div>
                  )
                })}
                {range(cardsPerGroup - card.length).map(i => (
                  <div key={i} className={'card'} />
                ))}
              </li>
            ))}
          </ul>
          <ol
            className={classNames(
              `w-full center mx-5`,
              'touch-pan-right xl:touch-pan-down',
              `relative flex justify-start mx-auto overflow-y-scroll scrollbar-hide w-full h-110p`,
              'col-start-1 col-span-1 row-start-2 row-span-1 flex-row',
              'md:center',
              'xl:col-start-2 xl:col-span-1 xl:row-start-1 xl:row-span-1 xl:flex-col xl:max-h-full xl:justify-center xl:mb-5 xl:ml-auto xl:mr-0 xl:z-30',
              'before:hidden before:content[" "] before:min-w-2/3 before:h-full',
              'after:hidden after:content[" "] after:min-w-2/3 after:h-full',
            )}
          >
            {group.map((card, groupIndex) => {
              return (
                <li
                  key={groupIndex}
                  className={classNames(
                    `min-w-24`,
                    `mr-5 mt-5 pb-1`,
                    `flex flex-col justify-start`,
                    `lg:mr-0 lg:pb-0 lg:justify-center`,
                    `border-b-4 border-transparent xl:border-b-0 xl:border-r-4`,
                    `z-10 transition-all`,
                    `lg:flex-row lg:px-4`,
                    groupIndex === currentGroupIndex && 'border-neutral-700',
                  )}
                >
                  <button
                    className={`min-h-full min-w-full`}
                    onClick={onClickThumb(groupIndex)}
                  >
                    {card.map((card, cardIndex) => {
                      return (
                        <GatsbyImage
                          key={cardIndex}
                          loading={`lazy`}
                          alt={card.altText}
                          image={card.thumb.gatsbyImageData}
                          className={classNames(
                            `drop-shadow-2xl`,
                            groupIndex === currentGroupIndex
                              ? 'opacity-100'
                              : 'opacity-75',
                          )}
                        />
                      )
                    })}
                  </button>
                </li>
              )
            })}
          </ol>
        </div>
      </div>
    )
  },
  (prev, next) => prev.className === next.className,
)

export default ProductCarousel

import React from 'react'
import { graphql } from 'gatsby'
import { useProduct } from '@/hooks/use-product'
import { classNames } from '@/utils/class-names'
import { ProductDetails } from '@/components/tailwind/product-details'
import { ProductCarousel } from '@/components/tailwind/product-carousel'
import { ProductForm } from '@/components/tailwind/product-form'

export const ProductSection = React.memo(
  ({
    product,
    children,
    className = 'bg-gradient-to-b from-transparent to-concrete-500',
  }) => {
    const { images } = useProduct(product)

    return (
      <section className={className}>
        <div className='container mx-auto px-5 sm:px-0'>
          <div
            className={classNames(
              'flex flex-col mx-auto pb-5 sm:mt-0',
              'lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:gap-x-12 sm:pb-0',
              'xl:grid xl:grid-cols-60/40 xl:grid-rows-1 xl:gap-x-12 xl:pb-0',
            )}
          >
            <div
              className={classNames(
                'lg:row-start-1 lg:row-span-2 lg:col-start-1 lg:col-span-1 lg:mt-0',
                'xl:row-start-1 xl:row-span-2 xl:col-start-1 xl:col-span-1 xl:content-start',
              )}
            >
              <ProductCarousel
                images={images}
                className={classNames(
                  'flex flex-col mx-0 w-full',
                  'sm:flex-row sm:mx-auto gap-8',
                )}
              />
            </div>

            <div
              className={classNames(
                'mt-5 lg:mt-0',
                'lg:row-start-1 lg:row-span-1 lg:col-start-2 lg:col-span-1 lg:mt-0',
                'xl:row-start-1 xl:row-span-1 xl:col-start-2 xl:col-span-1 xl:content-end',
              )}
            >
              <ProductDetails product={product} />
              <br />
              <ProductForm product={product} />
              {children}
            </div>

            <div
              className={classNames(
                'lg:row-start-2 lg:row-span-1 lg:col-start-2 lg:col-span-1',
                'xl:row-start-2 xl:row-span-1 xl:col-start-1 xl:col-span-2',
              )}
            ></div>
          </div>
        </div>
      </section>
    )
  },
  (prev, next) => {
    return true

    /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return fals
  */
  },
)

export const query = graphql`
  fragment ProductSection on ShopifyProduct {
    title
    description
    productType
    productTypeSlug: gatsbyPath(
      filePath: "/products/{ShopifyProduct.productType}"
    )
    tags
    priceRangeV2 {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    storefrontId
    media {
      ... on ShopifyMediaImage {
        id
        alt
        image {
          width
          height
          originalSrc
        }
      }
    }
    variants {
      availableForSale
      storefrontId
      title
      price
      compareAtPrice
      selectedOptions {
        name
        value
      }
    }
    options {
      name
      values
    }
  }
`

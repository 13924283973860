import { AddToCart } from '@/components/tailwind/add-to-cart'
import { NumericInput } from '@/components/tailwind/numeric-input'
import { RadioGroup } from '@headlessui/react'
import QuestionMarkCircleIcon from '@heroicons/react/solid/QuestionMarkCircleIcon'
import { ShoppingBagIcon } from '@heroicons/react/outline'
import React, { useRef, useState } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'

import { useCart } from '@/hooks/use-cart'
import { useProduct } from '@/hooks/use-product'
import { classNames } from '@/utils/class-names'

export const ProductForm = ({ product }) => {
  const {
    available,
    options,
    quantity,
    setQuantity,
    productVariant,
    setAvailable,
    checkAvailablity,
    handleOptionChange,
    price,
    hasVariants,
    hasImages,
    hasMultipleImages,
  } = useProduct(product)

  const { isCartVisible, onClickShowCart, cartCount } = useCart()

  const addToCartRef = useRef(null)
  const entry = useIntersectionObserver(addToCartRef, {})
  //const isAddToCartButtonVisible = !!entry?.isIntersecting

  const isBottomMenuVisible = !isCartVisible && !!entry?.isIntersecting

  const isOptionSelected = (name, value) => {
    if (!productVariant) {
      return false
    }

    const res = productVariant.selectedOptions.find(
      x => x.name === name && x.value === value,
    )

    return res
  }

  return (
    <>
      {/* Product form */}
      <section aria-labelledby='options-heading'>
        <h2 id='options-heading' className='sr-only'>
          Product options
        </h2>

        <form ref={addToCartRef}>
          <div className='sm:flex sm:justify-between'>
            {/* Size selector */}
            {hasVariants &&
              options.map(({ id, name, values }, index) => (
                <RadioGroup
                  onChange={value => {
                    handleOptionChange(index, { target: { value } })
                  }}
                >
                  <RadioGroup.Label className='text-sm text-base font-semibold uppercase tracking-wider'>
                    Select {name}
                  </RadioGroup.Label>
                  <div className='mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2'>
                    {values.map((value, index) => (
                      <RadioGroup.Option
                        as='div'
                        key={value}
                        value={value}
                        className={({ active }) =>
                          classNames(
                            value === 'Carbon' ? 'bg-gray-300' : '',
                            isOptionSelected(name, value)
                              ? 'ring-2 ring-purple-800'
                              : '',
                            'relative block border border-gray-300 rounded-lg p-4 cursor-pointer focus:outline-none transition-all',
                          )
                        }
                        disabled={value === 'Carbon'}
                      >
                        {({ active, checked }) => (
                          <>
                            <RadioGroup.Label
                              as='p'
                              className='text-base font-medium text-gray-900'
                            >
                              {value}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as='p'
                              className='mt-1 text-sm text-gray-500'
                            >
                              {value === 'Leather'
                                ? `44 Available`
                                : `Out of Stock`}
                            </RadioGroup.Description>
                            <div
                              className={classNames(
                                active ? 'border' : 'border-2',
                                checked
                                  ? 'border-purple-800'
                                  : 'border-transparent',
                                'absolute -inset-px rounded-lg pointer-events-none',
                              )}
                              aria-hidden='true'
                            />
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              ))}
          </div>

          {/*
          <div className='mt-10 inline-flex'>
            <NumericInput
              aria-label='Quantity'
              onIncrement={ev =>
                setQuantity(q => Math.min(q + 1, 20)) && ev.preventDefault()
              }
              onDecrement={ev =>
                setQuantity(q => Math.max(1, q - 1)) && ev.preventDefault()
              }
              onChange={ev =>
                setQuantity(ev.currentTarget.value) && ev.preventDefault()
              }
              value={quantity}
              min='1'
              max='20'
            />
          </div>
            */}
          <div className='mt-10 inline-flex w-full'>
            <AddToCart
              className='bg-mine-shaft-900 transition-all border border-transparent rounded-md py-3 px-8 mr-2 flex grow items-center justify-center text-base font-medium text-white hover:bg-mine-shaft-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-50 focus:ring-purple-800'
              variantId={productVariant.storefrontId}
              quantity={quantity}
              available={available}
            />
          </div>
          <nav
            className={classNames(
              isBottomMenuVisible ? 'translate-y-0' : 'translate-y-24',
              'md:hidden transition-all fixed z-30 bottom-0 inset-x-0  bg-white/50 backdrop-blur-md flex justify-between text-sm uppercase',
            )}
          >
            <div
              className={`container min-w-full mx-auto p-4 sm:px-8 sm:py-8 sm:min-w-auto`}
            >
              <div className={`flex flex-row justify-between`}>
                <button
                  onClick={onClickShowCart}
                  className='bg-black border border-transparent rounded-md py-3  flex w-1/2 items-center justify-center text-base font-medium text-white mr-4'
                >
                  <span
                    className={`border-r pr-4 mt-0 font-bold uppercase tracking-tight text-white`}
                  >
                    Open Bag
                  </span>
                  <span>
                    <ShoppingBagIcon
                      className={`ml-3 flex-shrink-0 h-5 w-5`}
                      aria-hidden='true'
                    />
                  </span>
                  <span className={`rounded-full bg-red text-sm font-sm`}>
                    {cartCount}
                  </span>
                  <span className='sr-only'>items in cart, view bag</span>
                </button>
                <AddToCart
                  className='bg-black border border-transparent rounded-md py-3  flex w-1/2 items-center justify-center text-base font-medium text-white'
                  variantId={productVariant.storefrontId}
                  quantity={quantity}
                  available={available}
                />
              </div>
            </div>
          </nav>
        </form>
      </section>
    </>
  )
}

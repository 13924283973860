exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-collections-shopify-collection-handle-tsx": () => import("./../../../src/pages/collections/{ShopifyCollection.handle}.tsx" /* webpackChunkName: "component---src-pages-collections-shopify-collection-handle-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-shopify-product-product-type-index-tsx": () => import("./../../../src/pages/products/{ShopifyProduct.productType}/index.tsx" /* webpackChunkName: "component---src-pages-products-shopify-product-product-type-index-tsx" */),
  "component---src-pages-products-shopify-product-product-type-shopify-product-handle-tsx": () => import("./../../../src/pages/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}.tsx" /* webpackChunkName: "component---src-pages-products-shopify-product-product-type-shopify-product-handle-tsx" */),
  "component---src-pages-products-smart-wallet-test-mdx": () => import("./../../../src/pages/products/smart-wallet-test.mdx" /* webpackChunkName: "component---src-pages-products-smart-wallet-test-mdx" */),
  "component---src-pages-products-smart-wallet-tsx": () => import("./../../../src/pages/products/smart-wallet.tsx" /* webpackChunkName: "component---src-pages-products-smart-wallet-tsx" */),
  "component---src-pages-products-vendor-shopify-product-vendor-tsx": () => import("./../../../src/pages/products/vendor/{ShopifyProduct.vendor}.tsx" /* webpackChunkName: "component---src-pages-products-vendor-shopify-product-vendor-tsx" */),
  "component---src-pages-support-privacy-policy-mdx": () => import("./../../../src/pages/support/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-support-privacy-policy-mdx" */),
  "component---src-pages-support-return-and-refund-policy-mdx": () => import("./../../../src/pages/support/return-and-refund-policy.mdx" /* webpackChunkName: "component---src-pages-support-return-and-refund-policy-mdx" */),
  "component---src-pages-support-shipping-policy-mdx": () => import("./../../../src/pages/support/shipping-policy.mdx" /* webpackChunkName: "component---src-pages-support-shipping-policy-mdx" */),
  "component---src-pages-support-terms-of-service-mdx": () => import("./../../../src/pages/support/terms-of-service.mdx" /* webpackChunkName: "component---src-pages-support-terms-of-service-mdx" */)
}


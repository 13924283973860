import React, { useState, FunctionComponent, ComponentProps } from 'react'
import fetch from 'isomorphic-fetch'
import ShopifyBuy from 'shopify-buy'
import { env } from '@/utils/env'
import isFunction from 'lodash/isFunction'
import { WindowState, CartState, StoreContextInterface } from '@/index.d'

export const client = ShopifyBuy.buildClient({
  domain: env('GATSBY_SHOPIFY_STORE_URL'),
  storefrontAccessToken: env('GATSBY_STOREFRONT_ACCESS_TOKEN'),
})

const defaultContext: StoreContextInterface = {
  client,
  cartState: {
    isCartEmpty: true,
    isCartLoading: false,
    isCartVisible: false,
    isCartDiscountActive: false,
    cartDiscountCode: null,
    cartCurrencyCode: 'USD',
    cartShippingTotal: '0',
    cartTaxTotal: '0',
    cartSubtotal: '0',
    cartSavingsTotal: '0',
    cartTotal: '0',
    cartCount: '0',
    cartItems: [],
    didJustAddCartItem: false,
    didJustAddCartDiscountCode: false,
    didJustRemoveCartDiscountCode: false,
  },
  windowState: {
    isScrollLocked: false,
  },
  checkout: {
    webUrl: '',
    checkoutUrl: '',
    id: 0,
    lineItemCount: 0,
    subtotalPrice: '0',
    lineItems: [],
    completedAt: '',
  },
  setCheckout: state => {},
  setCartState: state => {},
  setWindowState: state => {},
}

export const StoreContext = React.createContext(defaultContext)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

export const StoreProvider: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  const [checkout, updateCheckout] = useState(defaultContext.checkout)

  const [windowState, setWindowState] = useState(defaultContext.windowState)

  const [cartState, setCartState] = useState(defaultContext.cartState)

  const updateCartState = nextCartState => {
    setCartState({ ...cartState, ...nextCartState })
  }

  const setCheckout = (next: ShopifyBuy.Cart) => {
    console.log(`setCheckout: got id -> `, next.id)
    if (typeof window !== `undefined`) {
      localStorage.setItem(localStorageKey, String(next.id))
    }

    updateCheckout(next)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      if (localStorage.getItem(localStorageKey) === '0') {
        localStorage.setItem(localStorageKey, 'null')
      }

      const existingCheckoutID =
        typeof window !== `undefined`
          ? localStorage.getItem(localStorageKey)
          : 'null'

      console.log(`got existingCheckoutID -> `, existingCheckoutID)

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout: ShopifyBuy.Cart = await client.checkout.fetch(
            existingCheckoutID,
          )
          if (!existingCheckout.completedAt) {
            setCheckout(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, 'null')
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckout(newCheckout)
    }

    initializeCheckout()
  }, [])

  /*
  const addVariantToCart = (variantId: string, quantity: string) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then(res => {
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const removeLineItem = (checkoutID: string, lineItemID: string) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then(res => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = (
    checkoutID: string,
    lineItemID: string,
    quantity: string,
  ) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then(res => {
        setCheckout(res)
        setLoading(false)
      })
  }
  */

  return (
    <StoreContext.Provider
      value={{
        windowState,
        cartState,
        checkout,
        client,

        setCheckout,
        setCartState,
        setWindowState,

        updateCartState,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

import React, { useState } from 'react'
import { StoreContext } from '@/context/store-context'
import { useCart } from '@/hooks/use-cart'
import { useGtag } from '@/hooks/use-gtag'
import { useCountdown } from 'usehooks-ts'
import { preventDefault } from '@/utils/prevent-default'

export function useAddToCart<T> ({
  variantId,
  quantity,
}: {
  variantId: string
  quantity: string
}): {
  onClickAddCartItem: <T>(ev: React.SyntheticEvent) => Promise<boolean>
  addToCartCaption: string | boolean
  setAddToCartCaption: <T>(addToCartCaption: string) => void
  isAddToCartDisabled: boolean
  setIsAddToCartDisabled: <T>(isAddToCartDisabled: boolean) => void
  isAddToCartLoading: boolean
  setIsAddToCartLoading: <T>(isAddToCartLoading: boolean) => void
} {
  const { addCartItem, isCartLoading, setIsCartLoading } = useCart()

  const [addToCartCaption, updateCaption] = useState(false)
  const [isAddToCartDisabled, setIsAddToCartDisabled] = useState(false)
  const gtag = useGtag()

  async function onClickAddCartItem<T> (
    ev: React.SyntheticEvent,
  ): Promise<boolean> {
    preventDefault(ev)
    setIsCartLoading(true)
    await addCartItem(variantId, quantity)
    gtag('event', 'add_to_cart', { id: variantId, quantity })

    setAddToCartCaption('In Bag!')
    setIsCartLoading(false)

    return true
  }

  const [intervalValue, setIntervalValue] = useState(1000)

  const [
    count,
    { startCountdown, stopCountdown, resetCountdown },
  ] = useCountdown({
    countStart: 3,
    intervalMs: intervalValue,
  })

  const setAddToCartCaption = addToCartCaption => {
    setIsAddToCartDisabled(isAddToCartDisabled)
    updateCaption(addToCartCaption)
    resetCountdown()
    startCountdown()
  }

  if (addToCartCaption !== false && count === 1) {
    updateCaption(false)
    setIsAddToCartDisabled(false)
  }

  return {
    onClickAddCartItem,
    addToCartCaption,
    setAddToCartCaption,
    isAddToCartDisabled,
    setIsAddToCartDisabled,
    isAddToCartLoading: isCartLoading,
    setIsAddToCartLoading: setIsCartLoading,
  }
}

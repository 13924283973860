import { Script } from 'gatsby'
import * as React from 'react'

import { StoreProvider } from './src/context/store-context'

import './src/assets/styles/global.css'
import './src/assets/styles/reset.css'
import './src/assets/styles/variables.css'

export const wrapRootElement = ({ element }) => (
  <>
    <Script
      defer
      data-domain='anov.us'
      src='https://plausible.io/js/plausible.js'
    />
    <Script
      async
      src='https://www.googletagmanager.com/gtag/js?id=G-SC0CC154HV'
    />
    <Script strategy={`post-hydrate`}>
      {`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-SC0CC154HV');
`}
    </Script>
    <StoreProvider>{element}</StoreProvider>
  </>
)

import { useContext, useState, useEffect } from 'react'
import { StoreContext } from '@/context/store-context'

export function useScrollLock<T> (initialState: boolean = false): [isScrollLocked: boolean, setScrollLocked: <T>(value: boolean) => void] {
  const {
    windowState: { isScrollLocked, ...windowState },
    setWindowState,
  } = useContext(StoreContext)

  const setScrollLock = (next: boolean) => {
    setWindowState({ ...windowState, isScrollLocked: next })

    if (!window) {
      return
    }

    const container = window.document.querySelector('#___gatsby') as HTMLElement

    container.style['display'] = isScrollLocked ? 'none' : 'block'
  }

  
  useEffect(() => {
    if (!window) {
      return
    }

    const container = window.document.querySelector('#___gatsby') as HTMLElement

    container.style['display'] = isScrollLocked ? 'none' : 'block'
    return () => {
      if (!container) {
        return
      }

      container.style['display'] = 'block'
    }
  })

  return [isScrollLocked, setScrollLock]
}

import isFunction from 'lodash/isFunction'
import { useEffect, useState } from 'react'

export const useGtag = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    setIsLoaded(true)
  }, [isLoaded])

  return (...props) => {
    if (isLoaded && window && isFunction(window.gtag)) {
      window.gtag(...props)
      return
    }
    console.error(`[GTAG:Error] GTAG not loaded -> `, { ...props })
  }
}

import get from 'lodash/get';

type StoreConfigType = {
  GATSBY_STOREFRONT_ACCESS_TOKEN: string;
  GATSBY_SHOPIFY_STORE_URL: string;
  SHOPIFY_SHOP_PASSWORD: string;
  GOOGLE_ANALYTICS_ID: string;
};

const storeConfig: StoreConfigType = {
  GATSBY_STOREFRONT_ACCESS_TOKEN: get(
    process.env,
    `GATSBY_STOREFRONT_ACCESS_TOKEN`,
    `c133914f2a1e7c8eea6f4fb83e504172`,
  ),

  GATSBY_SHOPIFY_STORE_URL: get(
    process.env,
    `GATSBY_SHOPIFY_STORE_URL`,
    `47media.myshopify.com`,
  ),

  SHOPIFY_SHOP_PASSWORD: get(
    process.env,
    `SHOPIFY_SHOP_PASSWORD`,
    `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`,
  ),

  GOOGLE_ANALYTICS_ID: get(process.env, `GOOGLE_ANALYTICS_ID`, `XXX`),
};
export const env = (searchKey: string): string =>
  get(storeConfig, searchKey, null);

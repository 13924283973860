import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import isArray from 'lodash/isArray'
import get from 'lodash/get'

export const query = graphql`
  query UseStaticImageQuery {
    images: allFile(
      filter: {
        relativePath: {
          in: [
            "air-wallet-banner.png"
            "air-wallet-banner-mobile.png"
            "air-wallet-4.jpg"
            "air-wallet-4-new.png"
            "air-wallet-promo-1.jpg"
            "air-wallet-promo-2.jpg"
            "air-wallet-promo-3.jpg"
            "air-wallet-promo-4.jpg"
          ]
        }
      }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`

const findImage = (relativePath: string, data: any) => {
  if (!isArray(data.images.nodes)) {
    return null
  }

  const res = data.images.nodes.find((x: object & { relativePath: string }) =>
    x.relativePath.includes(relativePath),
  )

  return get(res, 'childImageSharp.gatsbyImageData')
}

export const useStaticImage = (relativePath: string) => {
  const data = useStaticQuery(query)

  const image = findImage(relativePath, data)

  return (props: object & { alt: string }) => (
    <GatsbyImage image={image} {...props} />
  )
}

export const useStaticImages = (...relativePaths: string[]) => {
  const data = useStaticQuery(query)

  return relativePaths.reduce((acc: any[], relativePath: string) => {
    const image = findImage(relativePath, data)

    return [
      ...acc,
      (props: object & { alt: string; loading: 'lazy' }) => (
        <GatsbyImage image={image} {...props} />
      ),
    ]
  }, [])
}

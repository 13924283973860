import React, { useState } from 'react'
import { classNames } from '@/utils/class-names'
import { useAddToCart } from '@/hooks/use-add-to-cart'

export const AddToCart = ({ variantId, quantity, available, ...props }) => {
  const {
    isAddToCartDisabled,
    isAddToCartLoading,
    onClickAddCartItem,
    addToCartCaption,
  } = useAddToCart({
    variantId,
    quantity,
  })

  return (
    <button
      type='submit'
      onClick={onClickAddCartItem}
      disabled={
        isAddToCartDisabled
          ? true
          : !available
          ? true
          : isAddToCartLoading
          ? true
          : false
      }
      {...props}
      className={classNames(
        props.className,
        'relative py-5',
        'prose tracking-wide',
      )}
    >
      <span
        className={classNames(
          'absolute w-full translate-y-0 flex flex-row justify-center transition-all',
          isAddToCartLoading ? 'translate-y-0' : 'translate-y-24 opacity-0',
        )}
      >
        <span
          className={`border-r pr-4 mt-0 font-bold uppercase tracking-tight text-white`}
        >
          Adding
        </span>
        <span className={`pl-4`}>
          <svg
            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              className='opacity-25'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'
            ></circle>
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            ></path>
          </svg>
        </span>
      </span>
      <span
        className={classNames(
          `font-bold uppercase tracking-tight text-white`,
          'absolute w-full transition-all',
          !addToCartCaption && available && !isAddToCartLoading
            ? 'translate-y-0'
            : 'translate-y-24 opacity-0',
        )}
      >
        Add to Bag
      </span>
      <span
        className={classNames(
          `font-bold uppercase tracking-tight text-white`,
          'absolute w-full transition-all',
          !addToCartCaption && !available && !isAddToCartLoading
            ? 'translate-y-0'
            : 'translate-y-24 opacity-0',
        )}
      >
        Out of Stock
      </span>
      <span
        className={classNames(
          `font-bold uppercase tracking-tight text-white`,
          'absolute w-full transition-all',
          addToCartCaption && !isAddToCartLoading
            ? 'translate-y-0'
            : 'translate-y-24 opacity-0',
        )}
      >
        {addToCartCaption}
      </span>
    </button>
  )
}

import { ShieldCheckIcon } from '@heroicons/react/outline'
import { CheckIcon, StarIcon } from '@heroicons/react/solid'
import React from 'react'

import useProduct from '@/hooks/use-product'
import classNames from '@/utils/class-names'

export const ProductDetails = ({ product, reviews = [] }) => {
  const { title, vendor, price, compareAtPrice, description } = useProduct(
    product,
  )

  return (
    <>
      <div className='leading-6 break-words text-neutral-600'>
        <div
          className='relative my-0 mx-auto w-full break-words'
          style={{ zIndex: 1, maxWidth: '90rem' }}
        >
          <div className='w-full text-neutral-600'>
            <div className='flex'>
              <div //className='mx-auto px-5 md:px-0'>
              >
                {vendor && (
                  <h2 className='mb-5 text-base font-semibold uppercase tracking-wider'>
                    {vendor}
                  </h2>
                )}
                <p className='mt-0 text-3xl font-extrabold uppercase tracking-tight sm:text-4xl text-neutral-700'>
                  {title}
                </p>
                {compareAtPrice ? (
                  <p className='mb-5 text-2xl font-extrabold uppercase tracking-tight sm:text-3xl text-neutral-500'>
                    <span className={`line-through text-red-500`}>
                      {compareAtPrice}
                    </span>
                    <span className={`pl-2 text-green-500`}>{price}</span>
                  </p>
                ) : (
                  <p className='mb-5 text-2xl font-extrabold uppercase tracking-tight sm:text-3xl text-neutral-500'>
                    {price}
                  </p>
                )}

                {description
                  .split('. ')
                  .map(p => `${p}.`.replace(/\.+/g, ''))
                  .map(
                    (p, i) =>
                      p !== p.length > 3 && (
                        <p key={i} className='mt-3'>
                          {p}.
                        </p>
                      ),
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductDetails
